import { ADMIN_DETAIL , ADMIN_TOKEN, REFRESH_TOKEN} from "../types";

const INITIAL_STATE = {
    admin_data: null,
    refresh_token:null
};

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADMIN_DETAIL:
            return {
                ...state,
                admin_data: payload,
            };
        case ADMIN_TOKEN:
            return {
                ...state,
                admin_token: payload,
            };
        case REFRESH_TOKEN:
            return {
                ...state,
                refresh_token: payload,
            };

        default:
            return state;
    }
};
