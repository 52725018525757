import { Suspense, lazy, useEffect } from "react";
import { Helmet } from "react-helmet"
import ErrorBoundary from "./ErrorBoundary";
import { persistor, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import Toast from "./Constant/Toast";
import CustomLazyLoading from "./Components/CustomLazyLoading";


function App() {
  const AppRouter = lazy(() => import("./Routes"));

  /* Handling Net Status & Visible Title */

  // Function to handle visibility change
  const handleVisibilityChange = () => {
    if (document.hidden) {
      document.title = "907 Navigation";
    } else {
      document.title = "907 Navigation";
    }
  };

  useEffect(() => {
    const handleOnline = () => {
      Toast("s", "Internet connection restored!", "top-center");
    };

    const handleOffline = () => {
      Toast("w", "Internet connection lost!", "top-center");
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>907 Navigation  ADMIN</title>
        <link rel="canonical" href="https://master.d2ms0pgeoewk5j.amplifyapp.com/static/media/logo.9f5d9a7ea18f71957c6b.png" /> 
        <meta property="og:image" content="https://master.d2ms0pgeoewk5j.amplifyapp.com/static/media/logo.9f5d9a7ea18f71957c6b.png" /> 
        <meta name="description" content="907 Navigation " />
      </Helmet>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Suspense fallback={<CustomLazyLoading />}>
            <ErrorBoundary>
              <AppRouter />
            </ErrorBoundary>
          </Suspense>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
